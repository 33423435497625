<template>
  <!-- eslint-disable -->
   <b-card>
      <b-overlay :show="!balancePayments" rounded="sm">
         <div class="invoice-register-table pt-1">
            <div class="float-left ir-btn-wrapper">
               <b-button v-b-modal.export-modal variant="primary" class="ir-btn billing-top-btn">
                  Export
               </b-button>
            </div>
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
               <b-form-group>
                  <div class="d-flex align-items-center">
                     <label class="mr-1">Search</label>
                     <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                  </div>
               </b-form-group>
            </div>
            <!-- table -->
            <vue-good-table v-if="balancePayments" ref="printMe" :columns="columns" :rows="balancePayments.balancePayments || balancePayments"
               :search-options="{ enabled: false, externalQuery: searchTerm }"
               :pagination-options="{ enabled: true, perPage: pageLength }" class="mt-2">
               <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'proformaNumber'">
                     <a :href="`/invoice/current/${props.row.id}`">#{{ props.row.credit_note}}</a>
                  </span>
                  <span
                  v-if="props.column.field === 'open'">
                     <a class="btn btn-success btn-sm" :href="`/invoice/current/${props.row.id}`">Open</a>
                  </span>

                  <span v-else-if="props.column.field === 'user' && isAdmin === true ">
                     {{ `${props.row.user_forename} ${props.row.user_surname}` }}
                  </span>

                  <span v-else-if="props.column.field === 'user_email' && isAdmin === true ">
                     {{ `${props.row.user_email}` }}
                  </span>

                  <span v-else-if="props.column.field === 'statusText'" class="badge badge-light-success">
                     {{ (props.row.invoice_status).toUpperCase() }} - BALANCE
                  </span>


                  <span v-else-if="props.column.field === 'invoiceValue'">
                     {{ `€ ${Number(props.row.invoice_value).toFixed(2)}` }}
                  </span>

                  <span v-else-if="props.column.field === 'invoiceDate'">
                     {{ props.row.issue_date }}
                  </span>

               </template>

               <!-- pagination -->
               <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap mt-1">
                     <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                           Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['10', '25', '50']" class="mx-1"
                           @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                     </div>
                     <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                           last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                           @input="(value) => props.pageChanged({ currentPage: value })">
                           <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                           </template>
                           <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                           </template>
                        </b-pagination>
                     </div>
                  </div>
               </template>
            </vue-good-table>
         </div>
      </b-overlay>
      <b-modal id="export-modal" cancel-variant="outline-secondary" hide-footer centered title="Export Table">
         <div class="text-center">
            <b-button variant="primary" class="activation-btn" @click="downloadCSV">
               To CSV
            </b-button><br>
            <download-excel style="margin:10px; width: 16rem" class="btn btn-primary" :data="json_data"
               :fields="json_fields" worksheet="WIRE Transfer" name="balance_payments.xls">
               To Excel
            </download-excel>
         </div>
      </b-modal>

   </b-card>
</template>

<script>
/* eslint-disable */
import {
   // eslint-disable-next-line no-unused-vars
   BPagination, BFormSelect, BCard, BOverlay, BButton, BBadge, BModal,
   BFormGroup, BFormInput, BPopover, BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import moment from 'moment'
import vSelect from 'vue-select'
import axios from '@/libs/axios'
import Vue from 'vue'
import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel)

export default {
   components: {
      VueGoodTable,
      BPagination,
      BOverlay,
      BButton,
      BCard,
      BModal,
      BFormGroup,
      BFormSelect,
      BFormInput,
      // eslint-disable-next-line vue/no-unused-components
      vSelect,
      BPopover,
      BSpinner,
   },
   data() {
      return {
         isNormalUser: false,
         pageLength: 25,
         isAdmin: false,
         columns: [
            {
               label: 'Pro Forma No.',
               field: 'proformaNumber',
               sortable: false,
            },
            {
               label: 'Open',
               field: 'open',
               sortable: true,

            },
            {
               label: 'User Email',
               field: 'user_email',
               sortable: false,

            },
            {
               label: 'User',
               field: 'user',
               sortable: false,
            },
            {
               label: 'Status',
               field: 'statusText',
               sortable: false,
            },
            {
               label: 'Value',
               field: 'invoiceValue',
               sortable: false,
            },
            {
               label: 'Issue Date',
               field: 'invoiceDate',
               sort: 'asc',
            },
         ],
         searchTerm: '',
         json_fields: {
            'Request Date': 'request_date',
            User: 'user',
            Amount: 'amount',
            Status: 'status',
            Invoice: 'invoice',
            'Approval date': 'approval_date',
         },
         json_data: [],
      }
   },
   computed: {
      balancePayments() {
         return this.$store.state.billing.balanceData
      },
   },
   created() {
      this.fetchBalancePayments()
      this.$store.state.auth.userData.isAdmin
   },
   mounted() {
      this.$store.state.billing.fetchBalance
      this.isAdmin = this.$store.state.auth.userData.isAdmin
      this.hideAdminColums(this.isAdmin)
   },
   methods: {
      getAmount(item) {
         const exchange_rate = 4.9264

         const value = parseFloat(item.payment_value)
         const cur = item.currency.toUpperCase()
         if (cur === 'RON') return value / exchange_rate
         return value
      },
      hideUserColumn() {
         const isUser = this.isUser()
         if (isUser) {
            this.$set(this.columns[1], 'hidden', true)
            this.json_fields = {
               'Request Date': 'request_date',
               Amount: 'amount',
               Status: 'status',
               Invoice: 'invoice',
               'Approval date': 'approval_date',
            }
         }
      },
      isUser() {
         const user = this.$store.state.auth.userData
         if (user.userRole === 'User') return true
         return false
      },
      fetchBalancePayments() {
         this.$store.dispatch('billing/fetchBalance')
            .catch(error => { console.log(error) })
      },
      getPackage(inv) {
      let str = ''
      if (inv.type !== 4 && inv.type !== 3) str = 'Custom' // str = inv.invoice_rows[0].item ?? 'Custom'
      else if (inv.type === 4) str = 'Optional feature'
      else if (inv.overage === 'conversions') str = 'Overage Conversions'
      else str = 'Overage Clicks'

      return str
    },
      getExcelData() {
         /* eslint-disable no-new-object */
         let el = new Object()
         /* eslint-enable no-new-object */
         el = this.$refs.printMe.$refs.table
         const isUser = this.isUser()
         Object.keys(el.rows).forEach(key => {
            if (key >= 1) {
               if (isUser) {
                  const row = {
                     request_date: el.rows[key].cells[0].textContent.trim(),
                     amount: el.rows[key].cells[1].textContent.trim(),
                     status: el.rows[key].cells[2].textContent.trim(),
                     invoice: el.rows[key].cells[3].textContent.trim(),
                     approval_date: el.rows[key].cells[4].textContent.trim(),
                  }
                  this.json_data.push(row)
               } else {
                  const row = {
                     request_date: el.rows[key].cells[0].textContent.trim(),
                     user: el.rows[key].cells[1].textContent.trim(),
                     amount: el.rows[key].cells[2].textContent.trim(),
                     status: el.rows[key].cells[3].textContent.trim(),
                     invoice: el.rows[key].cells[4].textContent.trim(),
                     approval_date: el.rows[key].cells[5].textContent.trim(),
                  }
                  this.json_data.push(row)
               }
            }
         })
      },

      downloadCSV() {
         const content = this.getContents()
         const csv = this.convertToCSV(content)
         const filename = 'balance_payments.csv'
         const blob = new Blob([`\ufeff${csv}`], { type: 'text/csv; charset=utf-8' })
         this.downloadFile(blob, filename)
      },

      downloadFile(blob, filename) {
         const link = document.createElement('a')
         const url = URL.createObjectURL(blob)
         link.setAttribute('href', url)
         link.setAttribute('download', filename)
         link.style.visibility = 'hidden'
         link.id = 'download_to_download'
         document.body.appendChild(link)
         document.getElementById(link.id).click()

         // remove to make sure a new csv file will be downloaded and not the previous one
         const element = document.getElementById(link.id)
         element.remove()
      },

      getContents() {
         const user = this.isUser()
         let header = []
         if (user) {
            header = [
               'Invoice No.',
               'Package',
               'Status',
               'Value',
               'Issue Date',
            ]
         } else {
            header = [
               'Invoice No.',
               'User',
               'Package',
               'Status',
               'Value',
               'Issue Date',
            ]
         }

         /* eslint-disable no-new-object */
         let el = new Object()
         /* eslint-enable no-new-object */
         el = this.$refs.printMe.$refs.table

         const rows = []
         Object.keys(el.rows).forEach(key => {
            if (key >= 1) {
               if (user) {
                  const row = []
                  row.push(el.rows[key].cells[0].textContent.trim())
                  row.push(el.rows[key].cells[1].textContent.trim())
                  row.push(el.rows[key].cells[2].textContent.trim())
                  row.push(el.rows[key].cells[3].textContent.trim())
                  row.push(el.rows[key].cells[4].textContent.trim())
                  rows.push(row)
               } else {
                  const row = []
                  row.push(el.rows[key].cells[0].textContent.trim())
                  row.push(el.rows[key].cells[1].textContent.trim())
                  row.push(el.rows[key].cells[2].textContent.trim())
                  row.push(el.rows[key].cells[3].textContent.trim())
                  row.push(el.rows[key].cells[4].textContent.trim())
                  row.push(el.rows[key].cells[5].textContent.trim())
                  rows.push(row)
               }
            }
         })

         rows.unshift(header)
         return JSON.stringify(rows)
      },

      convertToCSV(objArray) {
         const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
         let str = ''
         for (let i = 0; i < array.length; i++) { // eslint-disable-line
            let line = ''
            for (const index in array[i]) { // eslint-disable-line
               if (line !== '') line += ','
               line += array[i][index]
            }
            str += `${line}\r\n` // eslint-disable-line
         }
         return str
      },
      sortFnUser(x, y, col, rowX, rowY) { // eslint-disable-line
         const value1 = rowX.email.trim()// get x
         const value2 = rowY.email.trim()// get y
         // test values
         return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
      },
      sortFnAmount(x, y, col, rowX, rowY) { // eslint-disable-line
         const value1 = parseFloat(rowX.amount)// get x
         const value2 = parseFloat(rowY.amount)// get y
         // test values
         return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
      },
      sortFnApproveDate(x, y, col, rowX, rowY) { // eslint-disable-line
         const value1 = Date.parse(this.filterFormatDate(rowX.approvalDate))// get x
         const value2 = Date.parse(this.filterFormatDate(rowY.approvalDate))// get y
         // test values
         return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
      },
      sortFnReqDate(x, y, col, rowX, rowY) { // eslint-disable-line
         const value1 = Date.parse(this.filterFormatDate(rowX.requestDate))// get x
         const value2 = Date.parse(this.filterFormatDate(rowY.requestDate))// get y
         // test values
         return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
      },

      filterFormatDate(date) {
         const d = new Date(date)
         let month = `${d.getMonth() + 1}`
         let day = `${d.getDate()}`
         const year = d.getFullYear()
         if (month.length < 2) month = `0${month}`
         if (day.length < 2) day = `0${day}`
         return [year, month, day].join('-')
      },
      //Hide user column if user is not admin
      hideAdminColums(){
         if(!this.isAdmin){
            this.$set(this.columns[3], 'hidden', true)
            this.$set(this.columns[2], 'hidden', true)
         }
      },
   },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker {
   width: 100% !important;
}

.invoice-register-table {
   thead tr {
      background: #f3f2f7;
   }

   th,
   td {
      font-size: 14px;
      text-align: center !important;
      vertical-align: middle !important;
   }

   td {
      height: 62px;
   }
}

.ir-btn-wrapper {
   width: 30rem;

   .ir-btn {
      width: 100%;
      max-width: 148px;
   }
}

.activation-btn {
   width: 100%;
   max-width: 16rem;
}

.modal .modal-header .close {
   margin: 2px
}
</style>
